import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { getCurrentSchedule, finalizeSchedule, cleanUpSchedules } from '../api';
import {
  Box,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Grid,
  Button,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import AwayIcon from '@mui/icons-material/DirectionsBus';

const ItemTypes = {
  MATCH: 'match',
};

const DraggableMatch = ({ match, index, legIndex, roundIndex, moveMatch }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.MATCH,
    item: { index, legIndex, roundIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.MATCH,
    hover: (item) => {
      if (item.index !== index || item.legIndex !== legIndex || item.roundIndex !== roundIndex) {
        moveMatch(item.legIndex, item.roundIndex, item.index, legIndex, roundIndex, index);
        item.index = index;
        item.legIndex = legIndex;
        item.roundIndex = roundIndex;
      }
    },
  });

  return (
    <div ref={(node) => drag(drop(node))} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <Card className="mb-2 shadow-lg border border-gray-300">
        <CardContent className="flex items-center justify-between">
          <Typography variant="h6" className="text-gray-700">
            <HomeIcon className="text-blue-600 mr-1" /> {match.home}
          </Typography>
          <Typography variant="h6" className="text-gray-700">
            <AwayIcon className="text-green-600 mr-1" /> {match.away}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};

const DropZone = ({ roundIndex, legIndex, moveMatch }) => {
  const [, drop] = useDrop({
    accept: ItemTypes.MATCH,
    drop: (item) => {
      moveMatch(item.legIndex, item.roundIndex, item.index, legIndex, roundIndex, 0);
    },
  });

  return (
    <div ref={drop} className="h-24 border-4 border-dashed border-gray-400 flex items-center justify-center">
      <Typography variant="body1" className="text-gray-500">
        Drop here
      </Typography>
    </div>
  );
};

const ScheduleMaker = () => {
  const { seasonId } = useParams();
  const [schedule, setSchedule] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadSchedule = async () => {
      setLoading(true);
      try {
        const response = await getCurrentSchedule(seasonId);
        //console.log(response.data); // Print fetched data to the console
        setSchedule(response.data);
      } catch (error) {
        console.error('Error fetching schedule:', error);
      }
      setLoading(false);
    };
    loadSchedule();
  }, [seasonId]);

  const moveMatch = (fromLegIndex, fromRoundIndex, fromIndex, toLegIndex, toRoundIndex, toIndex) => {
    const newSchedule = JSON.parse(JSON.stringify(schedule));
    const [removed] = newSchedule.legs[fromLegIndex].rounds[fromRoundIndex].matches.splice(fromIndex, 1);
    newSchedule.legs[toLegIndex].rounds[toRoundIndex].matches.splice(toIndex, 0, removed);
    setSchedule(newSchedule);
  };

  const saveSchedule = async () => {
    try {
      // Clean up previous non-finalized schedules
      await cleanUpSchedules();
  
      // Finalize the new schedule
      await finalizeSchedule(schedule);
      
      alert('Schedule saved successfully!');
    } catch (error) {
      console.error('Error saving schedule:', error);
      alert('Failed to save schedule.');
    }
  };
  

  if (loading) {
    return <CircularProgress />;
  }

  if (!schedule) {
    return <Typography>No schedule found</Typography>;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Box className="p-6 flex flex-col items-center">
        <Typography variant="h4" className="text-4xl font-bold mb-6 text-red-800">
          Kalendarz Rozgrywek
        </Typography>
        {schedule.legs.map((leg, legIndex) => (
          <div key={legIndex} className="w-full max-w-5xl mb-10 p-6 bg-white shadow-lg rounded-lg border border-gray-300">
            <Typography variant="h5" className="text-3xl font-bold mb-4 text-blue-700">
              Runda {legIndex + 1}
            </Typography>
            {leg.rounds.map((round, roundIndex) => (
              <div key={roundIndex} className="mb-6 p-4 bg-gray-100 rounded-lg">
                <Typography variant="h6" className="text-2xl font-semibold mb-4 text-green-700">
                  Kolejka {roundIndex + 1}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DropZone legIndex={legIndex} roundIndex={roundIndex} moveMatch={moveMatch} />
                  </Grid>
                  {round.matches.map((match, index) => (
                    <Grid item xs={12} key={index}>
                      <DraggableMatch
                        match={match}
                        index={index}
                        legIndex={legIndex}
                        roundIndex={roundIndex}
                        moveMatch={moveMatch}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
            ))}
          </div>
        ))}
        <Button
          variant="contained"
          color="primary"
          onClick={saveSchedule}
          className="mt-4 bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
        >
          Zapisz Harmonogram
        </Button>
      </Box>
    </DndProvider>
  );
};

export default ScheduleMaker;
