import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchStandings } from '../api';
import {
  Box,
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/system';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    overflowX: 'auto',
  },
  [theme.breakpoints.up('md')]: {
    width: '90%',
  },
  [theme.breakpoints.up('lg')]: {
    width: '90%',
  },
  [theme.breakpoints.up('xl')]: {
    width: '90%',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontWeight: 'bold',
  fontSize: '1.1rem', // Increase font size
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const Standings = () => {
  const { seasonId } = useParams();
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    const loadStandings = async () => {
      try {
        const response = await fetchStandings(seasonId);
        //console.log('Standings data:', response.data); // Log the data to see the structure
        const sortedStandings = response.data.sort((a, b) => {
          // Primary sorting by points
          if (a.points !== b.points) {
            return b.points - a.points;
          }
          // Secondary sorting by wins
          if (a.wins !== b.wins) {
            return b.wins - a.wins;
          }
          // Tertiary sorting by set ratio (sets_won / sets_lost)
          const aSetRatio = a.sets_won / a.sets_lost;
          const bSetRatio = b.sets_won / b.sets_lost;
          if (aSetRatio !== bSetRatio) {
            return bSetRatio - aSetRatio;
          }
          // Quaternary sorting by point ratio (points_won / points_lost)
          const aPointRatio = a.points_won / a.points_lost;
          const bPointRatio = b.points_won / b.points_lost;
          return bPointRatio - aPointRatio;
        });
        setStandings(sortedStandings);
      } catch (error) {
        console.error('Error fetching standings:', error);
      } finally {
        setLoading(false);
      }
    };

    loadStandings();
  }, [seasonId]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
<Box className="p-0 m-0 flex flex-col items-center bg-white-100 dark:bg-gray-900 min-h-screen">
  <Typography variant="h4" gutterBottom className="text-blue-800 dark:text-white p-2">
    Tabela
  </Typography>
  <StyledTableContainer component={Paper} className="shadow-lg rounded-lg overflow-hidden w-screen p-0 m-0">
    <Table className="w-screen" aria-label="Tabela">
      <TableHead>
        <TableRow>
          <StyledTableCell className="p-4">Lp</StyledTableCell>
          <StyledTableCell className="p-4 w-fit">Drużyna</StyledTableCell>
          <StyledTableCell align="right" className="p-4">Mecze</StyledTableCell>
          <StyledTableCell align="right" className="p-4">Punkty</StyledTableCell>
          <StyledTableCell align="right" className="p-4">Zwycięstwa</StyledTableCell>
          <StyledTableCell align="right" className="p-4">Porażki</StyledTableCell>
          <StyledTableCell align="right" className="p-4">Wygrane Sety</StyledTableCell>
          <StyledTableCell align="right" className="p-4">Przegrane Sety</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {standings.map((row, index) => (
          <StyledTableRow key={row.team_name} className="text-base">
            <TableCell component="th" scope="row" className="p-4">
              {index + 1}
            </TableCell>
            <TableCell component="th" scope="row" className="p-4 w-fit">
              {row.team_name}
            </TableCell>
            <TableCell align="right" className="p-4">{row.games}</TableCell>
            <TableCell align="right" className="p-4">{row.points}</TableCell>
            <TableCell align="right" className="p-4">{row.wins}</TableCell>
            <TableCell align="right" className="p-4">{row.losses}</TableCell>
            <TableCell align="right" className="p-4">{row.sets_won}</TableCell>
            <TableCell align="right" className="p-4">{row.sets_lost}</TableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  </StyledTableContainer>
</Box>

  );
  

};

export default Standings;
