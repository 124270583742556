import React, { useEffect, useState } from 'react';
import { fetchSeasons, deleteSeason } from '../api';
import {
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  Grid
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ManageIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router';

const SeasonsPage = () => {
  const [seasons, setSeasons] = useState([]);
  const navigate = useNavigate();

  const loadSeasons = async () => {
    const response = await fetchSeasons();
    setSeasons(response.data);
  };

  useEffect(() => {
    loadSeasons();
  }, []);

  const handleDelete = async (seasonId) => {
    if (window.confirm('Are you sure you want to delete this season?')) {
      await deleteSeason(seasonId);
      loadSeasons();
    }
  };

  return (
    <Box className="p-6 flex flex-col items-center bg-gray-100 min-h-screen">
      <Typography variant="h4" className="text-4xl font-bold mb-6 text-blue-800">
        Seasons
      </Typography>
      <Grid container spacing={4} className="mb-6 w-full max-w-5xl">
        {seasons.map((season) => (
          <Grid item xs={12} md={6} lg={4} key={season.id}>
            <Card className="shadow-lg border border-gray-300 rounded-lg">
              <CardContent>
                <Typography variant="h6" className="text-2xl font-bold text-gray-700 mb-2">
                  {`${season.name} (${season.year})`}
                </Typography>
                <Box className="flex justify-end mt-4">
                  <IconButton
                    aria-label="manage"
                    onClick={() => navigate(`/seasons/${season.id}/manage`)}
                    className="text-blue-600 hover:text-blue-800"
                  >
                    <ManageIcon />
                  </IconButton>
                  <IconButton
                    aria-label="edit"
                    onClick={() => navigate(`/seasons/${season.id}/edit`)}
                    className="text-yellow-600 hover:text-yellow-800"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDelete(season.id)}
                    className="text-red-600 hover:text-red-800"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate('/seasons/new')}
        className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded"
      >
        Create New Season
      </Button>
    </Box>
  );
};

export default SeasonsPage;
