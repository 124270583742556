import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Accordion, AccordionSummary, AccordionDetails, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';

const TeamCard = ({ team, getFullImageUrl, handlePlayerDialogOpen, seasonId }) => {
  return (
    <div className="bg-white dark:bg-gray-900 shadow-lg rounded-lg p-4 hover:bg-gray-200 dark:hover:bg-gray-950 transition-colors duration-300">
      <div className="flex items-center mb-4">
        <Avatar alt={team.name} src={getFullImageUrl(team.logo)} className="w-14 h-14 mr-4" />
        <div>
          <h3 className="text-xl font-medium hover:text-blue-800">
            <Link to={`/seasons/${seasonId}/teams/${team.id}/edit`}>{team.name}</Link>
          </h3>
          <p className="text-gray-600 dark:text-gray-400">{team.hall}</p>
        </div>
      </div>
      <div className="text-gray-700 dark:text-gray-300 mb-4">
        <p><strong>Kontakt:</strong> {team.contact_name}</p>
        <p><strong>Email:</strong> {team.contact_email}</p>
        <p><strong>Telefon:</strong> {team.contact_phone}</p>
        <p><strong>Adres:</strong> {team.address_street}, {team.address_city}, {team.address_postal_code}</p>
      </div>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <p className="font-semibold">Gracze</p>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            {team.players.map((player) => (
              <li key={player.id} className="flex justify-between items-center mb-2">
                <span>{`${player.first_name} ${player.last_name}`}</span>
                <IconButton edge="end" aria-label="info" onClick={() => handlePlayerDialogOpen(player)}>
                  <InfoIcon />
                </IconButton>
              </li>
            ))}
          </ul>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default TeamCard;
