import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

const PlayerDialog = ({ selectedPlayer, open, onClose, teams }) => {
  if (!selectedPlayer) return null;

  const teamName = teams.find((team) => team.players.some((player) => player.id === selectedPlayer.id))?.name;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className="dark:text-white">
        {selectedPlayer.first_name} {selectedPlayer.last_name}
      </DialogTitle>
      <DialogContent className="dark:bg-gray-800">
        <DialogContentText className="dark:text-gray-300">
          <div className="flex flex-col space-y-2">
            <div><strong>Zespół:</strong> {teamName}</div>
            <div><strong>Pozycja:</strong> {selectedPlayer.position}</div>
            <div><strong>Numer:</strong> {selectedPlayer.jersey_number}</div>
            <div><strong>Data urodzenia:</strong> {selectedPlayer.date_of_birth}</div>
            <div><strong>Weteran:</strong> {selectedPlayer.veteran ? 'Tak' : 'Nie'}</div>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions className="dark:bg-gray-800">
        <Button onClick={onClose} className="dark:text-white">Zamknij</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PlayerDialog;
