import React, { useState, useEffect } from 'react';
import { listPosts, deletePost } from '../api';
import {
  Box,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  List,
  CardActionArea,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import './ShowPostsPage.css'; // Import the CSS file

const ShowPostsPage = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [postToDelete, setPostToDelete] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loadPosts = async () => {
      setLoading(true);
      try {
        const response = await listPosts();
        setPosts(response.data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
      setLoading(false);
    };
    loadPosts();
  }, []);

  const handleDelete = async () => {
    try {
      await deletePost(postToDelete.id);
      setPosts(posts.filter(post => post.id !== postToDelete.id));
      setConfirmDeleteOpen(false);
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const handlePostClick = (postId) => {
    //console.log('Post ID:', postId);
    navigate(`/posts/${postId}/edit/`);
  };

  return (
    <Box className="p-6 flex flex-col items-center">
      <Typography variant="h4" className="text-4xl font-bold mb-6 text-blue-800">
        Aktualności
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Card className="w-full max-w-5xl shadow-lg">
          <CardContent>
            <List className="space-y-4">
              {posts.map((post) => (
                <Card key={post.id} className="p-4 shadow-md border border-gray-300">
                  <CardContent className="flex items-center justify-between">
                    <CardActionArea onClick={() => handlePostClick(post.id)}>
                      <div>
                        <Typography variant="h6" className="text-gray-700 font-bold">
                          {post.title}
                        </Typography>
                        <Typography variant="body2" className="text-gray-500">
                          {post.summary}
                        </Typography>
                        <Typography variant="body2" className="text-gray-500">
                          {parse(post.content.replace(/src="\/media\//g, 'src="http://localhost:8000/media/').replace(/<img/g, '<img class="limited-img"'))}
                        </Typography>
                      </div>
                    </CardActionArea>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        setPostToDelete(post);
                        setConfirmDeleteOpen(true);
                      }}
                    >
                      <DeleteIcon className="text-red-500" />
                    </IconButton>
                  </CardContent>
                </Card>
              ))}
            </List>
          </CardContent>
        </Card>
      )}
      <Dialog open={confirmDeleteOpen} onClose={() => setConfirmDeleteOpen(false)}>
        <DialogTitle>Potwierdź Usunięcie</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Czy na pewno chcesz usunąć ten post? Ta akcja nie może zostać cofnięta.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDeleteOpen(false)} color="primary">
            Anuluj
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Potwierdź
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ShowPostsPage;
