import React, { useState } from 'react';
import { TextField, Button, Box, Card, CardContent, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router';
import { login, fetchCurrentSeasonId } from '../api';

const LoginForm = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log('Login form submitted'); // Debugging log
    //console.log('Username:', username); // Debugging log
    //console.log('Password:', password); // Debugging log
  
    try {
      const response = await login({ username, password });
      //console.log('Login successful:', response.data); // Debugging log    
      onLoginSuccess(response.data.access);
  
      const seasonIdresponse = await fetchCurrentSeasonId();
      //console.log('Current season:', seasonIdresponse); // Debugging log
      const seasonId = seasonIdresponse.data.id
      if (!seasonId) {
        throw new Error('Current season not found.');
      }
      
      navigate(`/seasons/${seasonId}/manage`); // Navigate to the correct season management page
    } catch (err) {
      console.error('Login failed:', err); // Debugging log
      setError('Invalid credentials');
    }
  };
  

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        background: 'linear-gradient(-45deg, #001f3f, #0159a7, #001f3f)',
      }}
    >
      <Card sx={{ maxWidth: 400, width: '100%', padding: 2, mx: 2 }}>
        <CardContent>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12} sm={8}>
              <Typography variant="h5" component="div" align="center">
                Zaloguj
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} container justifyContent="center">
              <img src="/img/logo_ALS.png" alt="Logo" style={{ height: '5rem' }} />
            </Grid>
          </Grid>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
            <TextField
              label="Uzytkownik"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              fullWidth
              margin="normal"
            />
            <TextField
              label="Hasło"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              fullWidth
              margin="normal"
            />
            {error && <Typography color="error">{error}</Typography>}
            <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
              Login
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default LoginForm;
