import React, { useEffect, useState } from 'react';
import { fetchTeam, updateTeam, uploadFiles } from '../api';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  TextField,
  CardMedia,
  Grid
} from '@mui/material';

const getFullImageUrl = (path) => {
  if (!path) return '';
  return path.startsWith('http') ? path : `${process.env.REACT_APP_API_URL}/media${path.replace(/^\/media\//, '/')}`;
};

const EditTeamPage = () => {
  const { teamId } = useParams();
  const navigate = useNavigate();
  const [team, setTeam] = useState({
    name: '',
    logo: '',
    photo_team: '',
    team_image: '',
    hall: '',
    match_date: '',
    contact_name: '',
    contact_email: '',
    contact_phone: '',
    address_street: '',
    address_city: '',
    address_postal_code: '',
    season_year: '2023/2024'
  });
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [fileInputs, setFileInputs] = useState({});

  useEffect(() => {
    const loadTeam = async () => {
      setLoading(true);
      try {
        const response = await fetchTeam(teamId);
        const teamData = response.data;
        // Clean paths to remove redundant 'media' segments
        ['logo', 'photo_team', 'team_image'].forEach(key => {
          if (teamData[key]) {
            teamData[key] = teamData[key].replace(/\/media\//g, '/');
          }
        });
        setTeam({
          ...teamData,
          season_year: teamData.season ? teamData.season.year : '2023/2024'
        });
      } catch (error) {
        console.error('Error fetching team:', error);
      }
      setLoading(false);
    };
    loadTeam();
  }, [teamId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTeam((prevTeam) => ({
      ...prevTeam,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFileInputs((prevInputs) => ({
      ...prevInputs,
      [name]: files[0],
    }));
  };

  const handleRemoveImage = (name) => {
    setTeam((prevTeam) => ({
      ...prevTeam,
      [name]: ''
    }));
  };

  const handleSaveTeam = async () => {
    setSaving(true);
    try {
      const updatedTeam = { ...team };

      // Upload new files if any
      if (Object.keys(fileInputs).length > 0) {
        const formData = new FormData();
        Object.entries(fileInputs).forEach(([key, file]) => {
          formData.append('files', file);
        });
        const uploadResponse = await uploadFiles(formData);
        uploadResponse.data.forEach((fileInfo, index) => {
          if (fileInfo.path) {
            updatedTeam[Object.keys(fileInputs)[index]] = fileInfo.path;  // Use the correct path
          }
        });
      }

      // Clean paths to remove redundant 'media' segments
      ['logo', 'photo_team', 'team_image'].forEach(key => {
        if (updatedTeam[key]) {
          updatedTeam[key] = updatedTeam[key].replace(/\/media\//g, '/');
        }
      });

      // Remove properties that are not part of the expected payload
      delete updatedTeam.id;
      delete updatedTeam.season;
      delete updatedTeam.players;

      await updateTeam(teamId, updatedTeam);
      alert('Zespół został zaktualizowany!');
      navigate(`/seasons/${team.season.id}/teams`);
    } catch (error) {
      console.error('Error updating team:', error);
      alert('Aktualizacja zespołu nie powiodła się.');
    }
    setSaving(false);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ p: 6, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h4" gutterBottom className="text-red-600">
        Edytuj Zespół
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Card sx={{ mb: 4, width: '100%', maxWidth: 800 }}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  label="Nazwa Zespołu"
                  name="name"
                  value={team.name}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
  
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">Obecne Logo:</Typography>
                {team.logo ? (
                  <Box className="thumbnail-container">
                    <CardMedia
                      component="img"
                      height="100"
                      image={getFullImageUrl(team.logo)}
                      alt="Logo"
                      sx={{ maxHeight: 100, maxWidth: '100%', objectFit: 'contain', mb: 1 }}
                    />
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleRemoveImage('logo')}
                      size="small"
                      sx={{  mb: 1 }}
                    >
                      Usuń
                    </Button>
                  </Box>
                ) : (
                  <Typography>Brak zdjęcia</Typography>
                )}
                <input type="file" name="logo" onChange={handleFileChange} />
              </Grid>
  
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle1">Obecne Zdjęcie Zespołu:</Typography>
                {team.photo_team ? (
                  <Box className="thumbnail-container">
                    <CardMedia
                      component="img"
                      height="100"
                      image={getFullImageUrl(team.photo_team)}
                      alt="Zdjęcie Zespołu"
                      sx={{ maxHeight: 100, maxWidth: '100%', objectFit: 'contain', mb: 1 }}
                    />
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleRemoveImage('photo_team')}
                      size="small"
                      sx={{  mb: 1 }}
                    >
                      Usuń
                    </Button>
                  </Box>
                ) : (
                  <Typography>Brak zdjęcia</Typography>
                )}
                <input type="file" name="photo_team" onChange={handleFileChange} />
              </Grid>
  

  
              <Grid item xs={12}>
                <TextField
                  label="Sala"
                  name="hall"
                  value={team.hall}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Data Meczu"
                  name="match_date"
                  value={team.match_date}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Imię i Nazwisko Kontaktu"
                  name="contact_name"
                  value={team.contact_name}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email Kontaktu"
                  name="contact_email"
                  value={team.contact_email}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Telefon Kontaktu"
                  name="contact_phone"
                  value={team.contact_phone}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Ulica"
                  name="address_street"
                  value={team.address_street}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Miasto"
                  name="address_city"
                  value={team.address_city}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Kod Pocztowy"
                  name="address_postal_code"
                  value={team.address_postal_code}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveTeam}
                sx={{ mr: 2 }}
                disabled={saving}
              >
                {saving ? 'Zapisywanie...' : 'Zapisz Zmiany'}
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate(`/seasons/${team.season.id}/teams`)}
              >
                Anuluj
              </Button>
            </Box>
          </CardContent>
        </Card>
      )}
    </Box>
  );

};

export default EditTeamPage;
