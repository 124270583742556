import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  CssBaseline,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Switch,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import EventNoteIcon from '@mui/icons-material/EventNote';
import GroupIcon from '@mui/icons-material/Group';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PeopleIcon from '@mui/icons-material/People';
import ArticleIcon from '@mui/icons-material/Article';
import TableChartIcon from '@mui/icons-material/TableChart';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

const drawerWidth = 240;

const Layout = ({ children, onLogout, themeMode, toggleTheme }) => {
  const navigate = useNavigate();
  const { seasonId } = useParams();
  const { pathname } = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isMainDashboard = !pathname.includes('/seasons/') || pathname === '/seasons';

  const drawer = (
    <div className={`h-full ${themeMode === 'dark' ? 'bg-gray-900 text-white' : 'bg-white text-black'} mt-16`}>
      <List className="border-1">
        {isMainDashboard ? (
          <>
            <ListItem button onClick={onLogout} className="hover:bg-red-600 hover:text-white">
              <ListItemText primary="Wyloguj" />
            </ListItem>
          </>
        ) : (
          <>
            <ListItem
              button
              onClick={() => navigate('/seasons')}
              className="hover:bg-gray-300 dark:hover:bg-gray-700"
            >
              <ListItemIcon>
                <EventNoteIcon />
              </ListItemIcon>
              <ListItemText primary="Sezony" />
            </ListItem>
            {seasonId && (
              <>
                <ListItem
                  button
                  onClick={() => navigate(`/seasons/${seasonId}/manage`)}
                  className="hover:bg-gray-300 dark:hover:bg-gray-700"
                >
                  <ListItemIcon>
                    <EventNoteIcon />
                  </ListItemIcon>
                  <ListItemText primary="Panel Sezonu" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => navigate(`/seasons/${seasonId}/teams`)}
                  className="hover:bg-gray-300 dark:hover:bg-gray-700"
                >
                  <ListItemIcon>
                    <GroupIcon />
                  </ListItemIcon>
                  <ListItemText primary="Zarządzaj Zespołami" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => navigate(`/seasons/${seasonId}/generate-fixture`)}
                  className="hover:bg-gray-300 dark:hover:bg-gray-700"
                >
                  <ListItemIcon>
                    <CalendarTodayIcon />
                  </ListItemIcon>
                  <ListItemText primary="Terminarz" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => navigate(`/seasons/${seasonId}/inactive-players`)}
                  className="hover:bg-gray-300 dark:hover:bg-gray-700"
                >
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Zawodnicy" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => navigate(`/seasons/${seasonId}/blog/show`)}
                  className="hover:bg-gray-300 dark:hover:bg-gray-700"
                >
                  <ListItemIcon>
                    <ArticleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Blog" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => navigate(`/seasons/${seasonId}/blog/create`)}
                  className="hover:bg-gray-300 dark:hover:bg-gray-700"
                >
                  <ListItemIcon>
                    <ArticleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Utwórz Wpis" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => navigate(`/seasons/${seasonId}/update-fixtures`)}
                  className="hover:bg-gray-300 dark:hover:bg-gray-700"
                >
                  <ListItemIcon>
                    <CalendarTodayIcon />
                  </ListItemIcon>
                  <ListItemText primary="Edytuj Wyniki" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => navigate(`/seasons/${seasonId}/standings`)}
                  className="hover:bg-gray-300 dark:hover:bg-gray-700"
                >
                  <ListItemIcon>
                    <TableChartIcon />
                  </ListItemIcon>
                  <ListItemText primary="Tabela" />
                </ListItem>
              </>
            )}
            <Divider />
            <ListItem>
              <ListItemText primary="Tryb ciemny" />
              <Switch
                checked={themeMode === 'dark'}
                onChange={toggleTheme}
                className="text-blue-500"
              />
            </ListItem>
            <ListItem button onClick={onLogout} className="hover:bg-red-600 hover:text-white">
              <ListItemText primary="Wyloguj" />
            </ListItem>
          </>
        )}
      </List>
    </div>
  );

  return (
    <div className={`${themeMode === 'dark' ? 'dark' : ''}`}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className="bg-gradient-to-r from-blue-900 to-blue-500"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className="mr-2 sm:hidden"
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Amatorska Liga Siatkówki
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
          className="bg-gray-100 dark:bg-gray-800"
        >
          <Toolbar />
          {children}
        </Box>
      </Box>
    </div>
  );
};

export default Layout;
