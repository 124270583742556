import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchSeasonById, updateSeason } from '../api';

const EditSeasonPage = () => {
  const { seasonId } = useParams();
  const navigate = useNavigate();
  const [season, setSeason] = useState(null);

  useEffect(() => {
    const loadSeason = async () => {
      const response = await fetchSeasonById(seasonId);
      setSeason(response.data);
      //console.log(response.data)
    };
    loadSeason();
  }, [seasonId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateSeason(seasonId, season);
    navigate('/seasons/1/manage');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSeason({
      ...season,
      [name]: value,
    });
  };

  if (!season) return <div>Loading...</div>;

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Edit Season
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          label="Name"
          name="name"
          value={season.name}
          onChange={handleChange}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Year"
          name="year"
          value={season.year}
          onChange={handleChange}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Start Date"
          type="date"
          name="start_date"
          value={season.start_date}
          onChange={handleChange}
          required
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End Date"
          type="date"
          name="end_date"
          value={season.end_date}
          onChange={handleChange}
          required
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Current Round"
          name="current_round"
          value={season.current_round}
          onChange={handleChange}
          required
          fullWidth
          margin="normal"
        />
        {/* Add the new field for Current Kolejka */}
        <TextField
          label="Current Kolejka"
          name="current_kolejka"
          value={season.current_kolejka}
          onChange={handleChange}
          required
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Update Season
        </Button>
      </Box>
    </Box>
  );
};

export default EditSeasonPage;
