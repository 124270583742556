import React, { useEffect, useState } from 'react';
import { fetchTeams, createPlayer, updatePlayer, deletePlayer } from '../api';
import { useParams } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Grid,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import 'tailwindcss/tailwind.css';

const PlayersPage = () => {
  const { seasonId } = useParams();
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortOption, setSortOption] = useState('Nazwisko');
  const [filter, setFilter] = useState('');
  const [teamFilter, setTeamFilter] = useState('');
  const [positionFilter, setPositionFilter] = useState('');
  const [editingPlayer, setEditingPlayer] = useState(null);
  const [newPlayer, setNewPlayer] = useState({
    first_name: '',
    last_name: '',
    date_of_birth: '',
    position: '',
    jersey_number: '',
    veteran: false,
    team_id: teams.length > 0 ? teams[0].id : '',
  });
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [playerToDelete, setPlayerToDelete] = useState(null);
  const [showAddPlayerDialog, setShowAddPlayerDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const loadTeams = async () => {
      setLoading(true);
      try {
        const response = await fetchTeams(seasonId);
        setTeams(response.data);
      } catch (error) {
        console.error('Error fetching teams:', error);
      }
      setLoading(false);
    };
    loadTeams();
  }, [seasonId]);

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleTeamFilterChange = (event) => {
    setTeamFilter(event.target.value);
  };

  const handlePositionFilterChange = (event) => {
    setPositionFilter(event.target.value);
  };

  const sortPlayers = (players) => {
    return [...players].sort((a, b) => {
      if (sortOption === 'Nazwisko') {
        return a.last_name.localeCompare(b.last_name);
      } else if (sortOption === 'team') {
        return a.teamName.localeCompare(b.teamName);
      } else if (sortOption === 'age') {
        return new Date(a.date_of_birth) - new Date(b.date_of_birth);
      } else if (sortOption === 'position') {
        return a.position.localeCompare(b.position);
      }
      return 0;
    });
  };

  const filterPlayers = (players) => {
    return players.filter(
      (player) =>
        player.last_name.toLowerCase().includes(filter.toLowerCase()) &&
        (teamFilter ? player.teamName === teamFilter : true) &&
        (positionFilter ? player.position === positionFilter : true)
    );
  };

  const getAllPlayers = () => {
    return teams.flatMap((team) =>
      team.players.map((player) => ({
        ...player,
        teamName: team.name,
      }))
    );
  };

  const handleEditPlayer = (player) => {
    setEditingPlayer(player);
    setNewPlayer({
      ...player,
      team_id: teams.find((team) => team.name === player.teamName).id,
    });
    setShowAddPlayerDialog(true);
  };

  const handleDeletePlayer = async () => {
    try {
      await deletePlayer(playerToDelete.id);
      const response = await fetchTeams(seasonId);
      setTeams(response.data);
      setSnackbarMessage('Zawodnik usunięty!');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error deleting player:', error);
    }
    setConfirmDeleteOpen(false);
  };

  const handleSavePlayer = async () => {
    try {
      const formattedPlayer = {
        first_name: newPlayer.first_name,
        last_name: newPlayer.last_name,
        date_of_birth: newPlayer.date_of_birth || '2000-01-01',
        position: newPlayer.position,
        jersey_number: newPlayer.jersey_number,
        veteran: newPlayer.veteran,
        team_id: newPlayer.team_id,
        season_id: seasonId,
      };

      if (editingPlayer) {
        await updatePlayer(editingPlayer.id, formattedPlayer);
        setSnackbarMessage('Zawodnik zaktualizowany!');
      } else {
        await createPlayer(formattedPlayer);
        setSnackbarMessage('Zawodnik dodany!');
      }

      const response = await fetchTeams(seasonId);
      setTeams(response.data);
      setSnackbarOpen(true);
      setShowAddPlayerDialog(false);
      setNewPlayer({
        first_name: '',
        last_name: '',
        date_of_birth: '',
        position: '',
        jersey_number: '',
        veteran: false,
        team_id: '',
      });
    } catch (error) {
      console.error('Error saving player:', error);
      setSnackbarMessage('Błąd podczas zapisywania zawodnika.');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  if (loading) {
    return <CircularProgress />;
  }

  const sortedPlayers = sortPlayers(getAllPlayers());
  const filteredPlayers = filterPlayers(sortedPlayers);

  return (

    <Box className="p-6 flex flex-col items-center bg-gray-50 dark:bg-gray-900 dark:text-white">
    <Typography variant="h4" className="main-area-header mb-6">
      Zawodnicy
    </Typography>
  
    <Grid container spacing={2} className="mb-6 w-full max-w-5xl">
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Sortuj według</InputLabel>
          <Select
            value={sortOption}
            onChange={handleSortChange}
            label="Sortuj według"
            className="dark:bg-gray-700 dark:text-white"
          >
            <MenuItem value="Nazwisko">Nazwisko</MenuItem>
            <MenuItem value="team">Zespół</MenuItem>
            <MenuItem value="age">Wiek</MenuItem>
            <MenuItem value="position">Pozycja</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          margin="dense"
          label="Filtruj według Nazwiska"
          fullWidth
          value={filter}
          onChange={handleFilterChange}
          className="dark:bg-gray-700 dark:text-white"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Filtruj według Zespołu</InputLabel>
          <Select
            value={teamFilter}
            onChange={handleTeamFilterChange}
            label="Filtruj według Zespołu"
            className="dark:bg-gray-700 dark:text-white"
          >
            <MenuItem value="">Wszystkie</MenuItem>
            {teams.map((team) => (
              <MenuItem key={team.id} value={team.name}>
                {team.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Filtruj według Pozycji</InputLabel>
                  <Select
          value={positionFilter}
          onChange={(e) => setPositionFilter(e.target.value)}
          label="Filtruj według Pozycji"
          className="dark:bg-gray-700 dark:text-white"
        >
          <MenuItem value="">Wszystkie</MenuItem>
          {[
            { value: 'Setter', label: 'Rozgrywający' },
            { value: 'Outside Hitter', label: 'Przyjmujący' },
            { value: 'Middle Blocker', label: 'Środkowy' },
            { value: 'Opposite', label: 'Atakujący' },
            { value: 'Libero', label: 'Libero' },
          ].map((position) => (
            <MenuItem key={position.value} value={position.value}>
              {position.label}
            </MenuItem>
          ))}
        </Select>
        </FormControl>
      </Grid>
    </Grid>
  
    <Button
      variant="contained"
      color="primary"
      onClick={() => {
        setEditingPlayer(null);
        setNewPlayer({
          first_name: '',
          last_name: '',
          date_of_birth: '',
          position: '',
          jersey_number: '',
          veteran: false,
          team_id: '',
        });
        setShowAddPlayerDialog(true);
      }}
      startIcon={<AddIcon />}
      className="mb-6"
    >
      Dodaj Zawodnika
    </Button>
  
    <Grid container spacing={2} className="w-full max-w-5xl pt-6">
      {filteredPlayers.map((player, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          key={player.id}
          className="flex justify-center"
        >
          <Card className="w-full h-full shadow-lg rounded-lg dark:bg-gray-800 transition-transform transform hover:scale-105">
            <CardContent className="flex flex-col h-full">
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                className="mb-4"
              >
                <Typography variant="h6" className="font-semibold">
                  {`${index + 1}. ${player.first_name} ${player.last_name}`}
                </Typography>
                {player.veteran && <StarIcon className="text-yellow-500" />}
              </Stack>
              <Typography
                variant="body2"
                className="flex-1 dark:text-gray-300"
              >
                Zespół: {player.teamName} <br />
                Pozycja: {player.position} <br />
                Data urodzenia: {player.date_of_birth}
              </Typography>
              <Box className="mt-4 flex justify-end">
                <IconButton
                  color="primary"
                  onClick={() => handleEditPlayer(player)}
                  className="hover:text-blue-500"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  color="secondary"
                  onClick={() => {
                    setPlayerToDelete(player);
                    setConfirmDeleteOpen(true);
                  }}
                  className="hover:text-red-500"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  
    <Dialog open={showAddPlayerDialog} onClose={() => setShowAddPlayerDialog(false)}>
      <DialogTitle>{editingPlayer ? 'Edytuj Zawodnika' : 'Dodaj Zawodnika'}</DialogTitle>
      <DialogContent className="space-y-4">
        <TextField
          margin="dense"
          label="Imię"
          fullWidth
          value={newPlayer.first_name}
          onChange={(e) => setNewPlayer({ ...newPlayer, first_name: e.target.value })}
          className="dark:bg-gray-700 dark:text-white"
        />
        <TextField
          margin="dense"
          label="Nazwisko"
          fullWidth
          value={newPlayer.last_name}
          onChange={(e) => setNewPlayer({ ...newPlayer, last_name: e.target.value })}
          className="dark:bg-gray-700 dark:text-white"
        />
        <TextField
          margin="dense"
          label="Data urodzenia"
          type="date"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          value={newPlayer.date_of_birth}
          onChange={(e) => setNewPlayer({ ...newPlayer, date_of_birth: e.target.value })}
          className="dark:bg-gray-700 dark:text-white"
        />
        <FormControl fullWidth className="mt-4">
        <InputLabel>Pozycja</InputLabel>        
        <Select
          value={newPlayer.position}
          onChange={(e) => setNewPlayer({ ...newPlayer, position: e.target.value })}
          label="Pozycja"
          className="dark:bg-gray-700 dark:text-white"
        >
          {[
            { value: 'Setter', label: 'Rozgrywający' },
            { value: 'Outside Hitter', label: 'Przyjmujący' },
            { value: 'Middle Blocker', label: 'Środkowy' },
            { value: 'Opposite', label: 'Atakujący' },
            { value: 'Libero', label: 'Libero' },
          ].map((position) => (
            <MenuItem key={position.value} value={position.value}>
              {position.label}
            </MenuItem>
          ))}
        </Select>
        </FormControl>        
        <TextField
          margin="dense"
          label="Numer koszulki"
          type="number"
          fullWidth
          value={newPlayer.jersey_number}
          onChange={(e) => setNewPlayer({ ...newPlayer, jersey_number: e.target.value })}
          className="dark:bg-gray-700 dark:text-white"
        />
        <FormControl fullWidth className="mt-4">
          <InputLabel>Zespół</InputLabel>
          <Select
            value={newPlayer.team_id}
            onChange={(e) => setNewPlayer({ ...newPlayer, team_id: e.target.value })}
            label="Zespół"
            className="dark:bg-gray-700 dark:text-white"
          >
            {teams.map((team) => (
              <MenuItem key={team.id} value={team.id}>
                {team.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
  
        <FormControlLabel
          control={
            <Checkbox
              checked={newPlayer.veteran}
              onChange={(e) => setNewPlayer({ ...newPlayer, veteran: e.target.checked })}
            />
          }
          label="Weteran"
          className="mt-4"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowAddPlayerDialog(false)} color="primary">
          Anuluj
        </Button>
        <Button onClick={handleSavePlayer} color="primary">
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  
    <Dialog open={confirmDeleteOpen} onClose={() => setConfirmDeleteOpen(false)}>
      <DialogTitle>Potwierdź Usunięcie Zawodnika</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Czy na pewno chcesz usunąć tego zawodnika? Ta akcja nie może zostać cofnięta.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setConfirmDeleteOpen(false)} color="primary">
          Anuluj
        </Button>
        <Button onClick={handleDeletePlayer} color="secondary">
          Potwierdź
        </Button>
      </DialogActions>
    </Dialog>
  
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleCloseSnackbar}
      message={snackbarMessage}
      action={
        <Button color="inherit" size="small" onClick={handleCloseSnackbar}>
          OK
        </Button>
      }
    />
  </Box>
  

  );
};

export default PlayersPage;
