import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { createSeason } from '../api';
import { useNavigate } from 'react-router';

const SeasonFormPage = () => {
  const [name, setName] = useState('');
  const [year, setYear] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentRound, setCurrentRound] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await createSeason({ name, year, start_date: startDate, end_date: endDate, current_round: currentRound });
    navigate('/seasons');
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Create New Season
      </Typography>
      <Box component="form" onSubmit={handleSubmit}>
        <TextField
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Year"
          value={year}
          onChange={(e) => setYear(e.target.value)}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Start Date"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          required
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End Date"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          required
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Current Round"
          value={currentRound}
          onChange={(e) => setCurrentRound(e.target.value)}
          required
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Create Season
        </Button>
      </Box>
    </Box>
  );
};

export default SeasonFormPage;
