import React from 'react';
import { Drawer, List, ListItem, ListItemText, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FiHome, FiTruck } from 'react-icons/fi';



const getFullImageUrl = (path) => {
  if (!path) return '';
  return path.startsWith('http') ? path : `${process.env.REACT_APP_API_URL}${path.replace(/^\/media\//, '/media/')}`;
};

const KolejkaDetails = ({ open, onClose, matches, legIndex, roundIndex }) => {
  return (
    <Drawer
      anchor="right" 
      open={open}
      onClose={onClose}
      PaperProps={{
        className: 'pt-16',
        style: { width: '500px' }  
      }}
    >
      <div className="bg-gray-800 dark:bg-gray-800 h-full flex flex-col text-gray-900 dark:text-gray-100">
        <div className="p-6 flex justify-between items-center mb-6 border-b pb-2 border-gray-300 dark:border-gray-700">
          <h2 className="text-3xl font-semibold text-gray-100">
            Runda {legIndex + 1} - Kolejka {roundIndex + 1}
          </h2>
          <IconButton onClick={onClose}>
            <CloseIcon className="text-gray-200 dark:text-gray-100"/>
          </IconButton>
        </div>
        <List className="flex-1 overflow-y-auto space-y-8 px-6 text-2xl">
          {matches.map((match, index) => (
            <ListItem
              key={index}
              className="bg-white dark:bg-gray-700 rounded-lg p-4 hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors duration-200 flex items-center"
            >
              <FiHome className="text-blue-500 dark:text-blue-400 mr-4" />
              <ListItemText
                primary={match.home}
                className="text-gray-900 dark:text-gray-100"
              />
              <img src={getFullImageUrl(match.home_logo)} alt={`${match.home} logo`} className="w-16 h-16 object-contain p-2 rounded-full" />
              <span className="mx-4 text-gray-500 dark:text-gray-400">vs</span>
              <img src={getFullImageUrl(match.away_logo)} alt={`${match.away} logo`} className="w-16 h-16 object-contain p-2 rounded-full" />
              <ListItemText
                primary={match.away}
                className="text-gray-900 dark:text-gray-100 text-right"
              />
              <FiTruck className="text-blue-500 dark:text-blue-400 ml-4" />
            </ListItem>
          ))}
        </List>
        <div className="p-6">
          <button
            onClick={onClose}
            className="w-full bg-red-500 dark:bg-red-600 text-white py-2 rounded-lg hover:bg-red-600 dark:hover:bg-red-700 transition-colors duration-200"
          >
            Zamknij
          </button>
        </div>
      </div>
    </Drawer>
  );
};

export default KolejkaDetails;
