import React, { useEffect, useState } from 'react';
import { fetchTeams, createTeam, deleteTeam, fetchSeasonById } from '../api';
import { useParams, useNavigate } from 'react-router-dom';
import { FiEdit, FiTrash } from 'react-icons/fi';

const TeamsPage = () => {
  const { seasonId } = useParams();
  const navigate = useNavigate();
  const [teams, setTeams] = useState([]);
  const [seasonYear, setSeasonYear] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAddTeamForm, setShowAddTeamForm] = useState(false);
  const [newTeamName, setNewTeamName] = useState('');
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [teamToDelete, setTeamToDelete] = useState(null);

  useEffect(() => {
    const loadSeasonDetails = async () => {
      setLoading(true);
      try {
        const response = await fetchSeasonById(seasonId);
        setSeasonYear(response.data.year);
        const teamsResponse = await fetchTeams(seasonId);
        setTeams(teamsResponse.data);
      } catch (error) {
        console.error('Error fetching season details:', error);
      }
      setLoading(false);
    };
    loadSeasonDetails();
  }, [seasonId]);

  const handleCreateTeam = async () => {
    const newTeam = {
      name: newTeamName,
      season_year: seasonYear,
    };

    try {
      await createTeam(newTeam);
      alert('Zespół utworzony pomyślnie!');
      const response = await fetchTeams(seasonId);
      setTeams(response.data);
      setNewTeamName('');
      setShowAddTeamForm(false);
    } catch (error) {
      console.error('Error creating team:', error);
      alert('Nie udało się utworzyć zespołu.');
    }
  };

  const handleDeleteTeam = async () => {
    try {
      await deleteTeam(teamToDelete);
      alert('Zespół usunięty pomyślnie!');
      const response = await fetchTeams(seasonId);
      setTeams(response.data);
    } catch (error) {
      console.error('Error deleting team:', error);
      alert('Nie udało się usunąć zespołu.');
    }
    setConfirmDeleteOpen(false);
  };

  const getFullImageUrl = (path) => {
    if (!path) return '';
    return path.startsWith('http') ? path : `${process.env.REACT_APP_API_URL}${path.replace(/^\/media\//, '/media/')}`;
  };


  const openDeleteDialog = (teamId) => {
    setTeamToDelete(teamId);
    setConfirmDeleteOpen(true);
  };

  const closeDeleteDialog = () => setConfirmDeleteOpen(false);

  const openAddTeamForm = () => setShowAddTeamForm(true);
  const closeAddTeamForm = () => setShowAddTeamForm(false);

  return (
    <div className="p-6 flex flex-col items-center">
      <h2 className="text-3xl font-bold text-gray-800 dark:text-gray-200 mb-6">
        Zarządzaj drużynami
      </h2>
      {loading && <div className="loader">Loading...</div>}
      {!loading && (
        <>
          <div className="w-full max-w-4xl bg-gray-200 dark:bg-gray-950 shadow-lg rounded-lg mb-6 p-6">
            <h3 className="text-xl font-semibold mb-4">Lista zespołów:</h3>
            {teams.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                {teams.map((team, index) => (
                <div key={team.id} className="flex flex-col justify-between bg-gray-100 dark:bg-gray-800 p-2 rounded-lg shadow-md">

                    <div className="flex justify-between items-center py-2">
                    <p className="text-center text-lg font-medium text-gray-800 dark:text-gray-300">{`${index + 1}. ${team.name}`}</p>
                      <img src={getFullImageUrl(team.logo)} alt={`${team.name} logo`} className="w-16 h-16 object-contain rounded-2xl" />      
                                    
                      </div>
                    
                    <div className="flex justify-between items-start p-4">
                    <button onClick={() => openDeleteDialog(team.id)} className="text-red-600 text-xs ">
                        <FiTrash className="w-6 h-6 " /> Usuń
                      </button>
                      <button onClick={() => navigate(`/seasons/${seasonId}/teams/${team.id}/edit`)} className="text-blue-600 md:text-blue-500 text-xs">
                        <FiEdit className="w-6 h-6" /> Zmień
                      </button>                      
                      </div>

                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-700">Brak dostępnych zespołów. Proszę dodać zespoły.</p>
            )}
            <button
              onClick={openAddTeamForm}
              className="mt-6 px-4 py-2 bg-blue-600 md:bg-blue-500 text-white rounded-lg flex items-center justify-center"
            >
              <FiEdit className="w-5 h-5 mr-2" />
              Dodaj Zespół
            </button>
          </div>

          {showAddTeamForm && (
            <div className="w-full max-w-4xl bg-white shadow-lg rounded-lg mb-6 p-6">
              <h3 className="text-xl font-semibold mb-4">Dodaj Nowy Zespół:</h3>
              <input
                type="text"
                value={newTeamName}
                onChange={(e) => setNewTeamName(e.target.value)}
                placeholder="Nazwa Zespołu"
                className="w-full p-2 mb-4 border rounded-lg"
              />
              <div className="flex justify-end">
                <button
                  onClick={handleCreateTeam}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg mr-2"
                >
                  Dodaj Zespół
                </button>
                <button
                  onClick={closeAddTeamForm}
                  className="px-4 py-2 border border-gray-300 text-gray-700 rounded-lg"
                >
                  Anuluj
                </button>
              </div>
            </div>
          )}

          {confirmDeleteOpen && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg shadow-lg max-w-md p-6">
                <h4 className="text-lg font-semibold mb-4">Potwierdź Usunięcie Zespołu</h4>
                <p className="text-gray-700 mb-4">Czy na pewno chcesz usunąć ten zespół? Ta akcja nie może zostać cofnięta.</p>
                <div className="flex justify-end">
                  <button onClick={closeDeleteDialog} className="px-4 py-2 bg-gray-300 rounded-lg mr-2">
                    Anuluj
                  </button>
                  <button onClick={handleDeleteTeam} className="px-4 py-2 bg-red-600 text-white rounded-lg">
                    Potwierdź
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TeamsPage;
