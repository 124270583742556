import React from 'react';
import LoginForm from '../components/LoginForm';

const LoginPage = ({ onLoginSuccess }) => {
  return (
    <div>
      <h1>Panel zarządzania ALS</h1>
      <LoginForm onLoginSuccess={onLoginSuccess}/>
    </div>
  );
};

export default LoginPage;
