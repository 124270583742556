import React from 'react';
import { Link } from 'react-router-dom';

const ScheduleList = ({ schedule, handleKolejkaClick }) => {
  return (
    <div className="mb-10">
      <h2 className="text-2xl font-semibold mb-4 hover:text-blue-800">
        <Link to={`/seasons/${schedule.seasonId}/generate-fixture/`}>Aktualny harmonogram</Link>
      </h2>
      {schedule && schedule.legs.length > 0 ? (
        <div className="bg-white dark:bg-gray-900 shadow-lg rounded-lg p-6 grid grid-cols-1 md:grid-cols-2 gap-6">
          {schedule.legs.map((leg, legIndex) => (
            <div key={legIndex} className="mb-6">
              <h3 className="text-xl font-medium mb-2">Runda {legIndex + 1}</h3>
              {leg.rounds.map((round, roundIndex) => (
                <div key={roundIndex} className="mb-4">
                  <button
                    className="text-lg font-semibold mb-2 bg-gray-200 dark:bg-gray-700 p-2 rounded-md hover:bg-gray-300 dark:hover:bg-gray-600"
                    onClick={() => handleKolejkaClick(legIndex, roundIndex)}
                  >
                    {roundIndex + 1} Kolejka
                  </button>
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <p>Brak harmonogramu do wyświetlenia</p>
      )}
    </div>
  );
};

export default ScheduleList;
