// MyUploadAdapter.js
const CLOUD_URL = 'https://services.amatorskasiatka.pl/api'; // Ensure this URL is correct
const LOCAL_URL = 'http://localhost:8000/api'; // Ensure this URL is correct
const API_BASE_URL = LOCAL_URL // Use LOCAL_URL or CLOUD_URL as needed

class MyUploadAdapter {
    constructor(loader) {
      this.loader = loader;
    }
  
    upload() {
      return this.loader.file.then(
        file =>
          new Promise((resolve, reject) => {
            const data = new FormData();
            data.append('files', file);
  
            fetch('http://localhost:8000/api/upload-many/', {
              method: 'POST',
              body: data,
            })
              .then(response => response.json())
              .then(result => {
                resolve({
                  default: `http://localhost:8000${result[0].path}`,
                });
              })
              .catch(error => {
                reject(error);
              });
          })
      );
    }
  
    abort() {
      // Handle abort
    }
  }
  
  export function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return new MyUploadAdapter(loader);
    };
  }
  