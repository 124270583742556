import React, { useState, useEffect } from 'react';
import { fetchSeasons, createPost, fetchCurrentSeasonId } from '../api';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { DatePicker } from '@mui/lab';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MyCustomUploadAdapterPlugin } from './MyUploadAdapter';

const BlogPage = () => {
  const { seasonId: paramSeasonId } = useParams();
  const [seasons, setSeasons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    summary: '',
    content: '',
    date: new Date(),
    season: paramSeasonId || '',
  });

  useEffect(() => {
    const loadSeasons = async () => {
      setLoading(true);
      try {
        const response = await fetchSeasons();
        setSeasons(response.data);
        
        let currentSeasonId = paramSeasonId;
        if (!currentSeasonId) {
          const currentSeasonResponse = await fetchCurrentSeasonId();
          currentSeasonId = currentSeasonResponse.data.id;
        }
        //console.log('Current season ID:', currentSeasonId);
        if (currentSeasonId) {
          setFormData((prevData) => ({
            ...prevData,
            season: currentSeasonId,
          }));
        }
      } catch (error) {
        console.error('Error fetching seasons or current season ID:', error);
      }
      setLoading(false);
    };
    loadSeasons();
  }, [paramSeasonId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      date,
    });
  };

  const handleContentChange = (event, editor) => {
    const data = editor.getData();
    setFormData({
      ...formData,
      content: data,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const selectedSeason = seasons.find((season) => season.id === formData.season);

    if (!selectedSeason) {
      alert('Selected season not found');
      setLoading(false);
      return;
    }

    const payload = {
      title: formData.title,
      summary: formData.summary,
      content: formData.content,
      slider: false,
      link: '',
      date: formData.date.toISOString().split('T')[0],
      season: {
        id: selectedSeason.id,
        name: selectedSeason.name,
        year: selectedSeason.year,
        start_date: selectedSeason.start_date,
        end_date: selectedSeason.end_date,
        current_round: selectedSeason.current_round,
      },
      images: [],
    };

    //console.log('Payload:', JSON.stringify(payload, null, 2));

    try {
      await createPost(payload);
      alert('Post zapisany pomyślnie!');
      setFormData({
        title: '',
        summary: '',
        content: '',
        date: new Date(),
        season: paramSeasonId || '',
      });
    } catch (error) {
      console.error('Error creating post:', error);
      alert('Nie udało się zapisać posta.');
    }
    setLoading(false);
  };

  return (
    <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Stwórz Post na Bloga
      </Typography>
      {loading && <CircularProgress />}
      {!loading && (
        <Card sx={{ width: '100%', maxWidth: 800, maxHeight: '80vh', overflow: 'auto' }}>
          <CardContent>
            <form onSubmit={handleSubmit} className="flex gap-4 flex-col">
              <TextField
                label="Tytuł"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                fullWidth
                sx={{ mb: 2 }}
              />
              <TextField
                label="Streszczenie"
                name="summary"
                value={formData.summary}
                onChange={handleInputChange}
                fullWidth
                sx={{ mb: 2 }}
              />
              <CKEditor
                editor={ClassicEditor}
                config={{
                  extraPlugins: [MyCustomUploadAdapterPlugin],
                }}
                data={formData.content}
                onChange={handleContentChange}
                className=""
              />
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Sezon</InputLabel>
                <Select
                  name="season"
                  value={formData.season || ''}
                  onChange={handleInputChange}
                  label="Sezon"
                >
                  {seasons.map((season) => (
                    <MenuItem key={season.id} value={season.id}>
                      {season.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <DatePicker
                label="Data"
                value={formData.date}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} fullWidth sx={{ mb: 2 }} />}
              />
              <Button variant="contained" color="primary" type="submit" fullWidth>
                Stwórz Post
              </Button>
            </form>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default BlogPage;
