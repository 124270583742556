import React, { useEffect, useState } from 'react';
import { fetchSeasonById, fetchTeams, getCurrentSchedule, fetchCurrentSeasonId } from '../api';
import { useParams } from 'react-router-dom';
import SeasonDetails from '../components/SeasonDetails';
import TeamCard from '../components/TeamCard';
import ScheduleList from '../components/ScheduleList';
import PlayerDialog from '../components/PlayerDialog';
import KolejkaDetails from '../components/KolejkaDetails';
import { Tabs, Tab } from '@mui/material';

const SeasonDashboardPage = () => {
  const { seasonId: seasonIdFromParams } = useParams();
  const [seasonId, setSeasonId] = useState(seasonIdFromParams || null);
  const [season, setSeason] = useState(null);
  const [teams, setTeams] = useState([]);
  const [schedule, setSchedule] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [playerDialogOpen, setPlayerDialogOpen] = useState(false);
  const [kolejkaOpen, setKolejkaOpen] = useState(false);
  const [currentLegIndex, setCurrentLegIndex] = useState(null);
  const [currentRoundIndex, setCurrentRoundIndex] = useState(null);
  const [tabIndex, setTabIndex] = useState(0); // State to manage the active tab

  // Fetch current season if seasonId is not provided
  useEffect(() => {
    const loadCurrentSeason = async () => {
      try {
        const currentSeasonResponse = await fetchCurrentSeasonId(); // Use a proper function to get the current season ID
        setSeasonId(currentSeasonResponse.data.id);
      } catch (error) {
        console.error('Błąd pobierania danych bieżącego sezonu:', error);
      }
    };

    if (!seasonId) {
      loadCurrentSeason();
    }
  }, [seasonId]);

  useEffect(() => {
    const loadSeasonData = async () => {
      try {
        const seasonResponse = await fetchSeasonById(seasonId);
        setSeason(seasonResponse.data);

        const teamsResponse = await fetchTeams(seasonId);
        setTeams(teamsResponse.data);

        const scheduleResponse = await getCurrentSchedule(seasonId);
        setSchedule(scheduleResponse.data);
      } catch (error) {
        console.error('Błąd pobierania danych:', error);
      } finally {
        setLoading(false);
      }
    };

    if (seasonId) {
      loadSeasonData();
    }
  }, [seasonId]);

  const handlePlayerDialogOpen = (player) => {
    setSelectedPlayer(player);
    setPlayerDialogOpen(true);
  };

  const handlePlayerDialogClose = () => {
    setSelectedPlayer(null);
    setPlayerDialogOpen(false);
  };

  const handleKolejkaClick = (legIndex, roundIndex) => {
    setCurrentLegIndex(legIndex);
    setCurrentRoundIndex(roundIndex);
    setKolejkaOpen(true);
  };

  const handleKolejkaClose = () => {
    setKolejkaOpen(false);
    setCurrentLegIndex(null);
    setCurrentRoundIndex(null);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const getFullImageUrl = (path) => {
    if (!path) return '';
    return path.startsWith('http') ? path : `${process.env.REACT_APP_API_URL}${path.replace(/^\/media\//, '/media/')}`;
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="p-6 dark:bg-gray-800 dark:text-white">
      <SeasonDetails season={season} />
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        className="mb-6"
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="Zespoły" />
        <Tab label="Terminarz" />
      </Tabs>
      {tabIndex === 0 && (
        <div className="mb-10">
          <h2 className="text-2xl font-semibold mb-4">Zespoły</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {teams.map((team) => (
              <TeamCard
                key={team.id}
                team={team}
                getFullImageUrl={getFullImageUrl}
                handlePlayerDialogOpen={handlePlayerDialogOpen}
                seasonId={season.id}
              />
            ))}
          </div>
        </div>
      )}
      {tabIndex === 1 && (
        <ScheduleList schedule={schedule} handleKolejkaClick={handleKolejkaClick} />
      )}
      <PlayerDialog
        selectedPlayer={selectedPlayer}
        open={playerDialogOpen}
        onClose={handlePlayerDialogClose}
        teams={teams}
      />
      {schedule && currentLegIndex !== null && currentRoundIndex !== null && (
        <KolejkaDetails
          open={kolejkaOpen}
          onClose={handleKolejkaClose}
          matches={schedule.legs[currentLegIndex].rounds[currentRoundIndex].matches}
          legIndex={currentLegIndex}
          roundIndex={currentRoundIndex}
        />
      )}
    </div>
  );
};

export default SeasonDashboardPage;
