import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { lightTheme, darkTheme } from './theme';
import LoginPage from './pages/LoginPage';
import SeasonsPage from './pages/SeasonsPage';
import SeasonFormPage from './pages/SeasonFormPage';
import EditSeasonPage from './pages/EditSeasonPage';
import SeasonDashboardPage from './pages/SeasonDashboardPage';
import TeamsPage from './pages/TeamsPage';
import GenerateFixturePage from './pages/GenerateFixturePage';
import EditTeamPage from './pages/EditTeamPage';
import InactivePlayersPage from './pages/PlayersPage';
import BlogPage from './pages/BlogPage';
import ShowPostsPage from './pages/ShowPostsPage';
import UpdateFixtures from './pages/UpdateFixtures';
import ScheduleMaker from './pages/ScheduleMaker'; // Importing the new ScheduleMaker page
import Layout from './components/Layout';
import Standings from './components/Standings'; // Ensure this is correctly imported
import EditPostPage from './pages/EditPostPage'; 

const App = () => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [themeMode, setThemeMode] = useState('light');

  const handleLoginSuccess = (token) => {
    setToken(token);
    localStorage.setItem('token', token);
  };

  const handleLogout = () => {
    setToken(null);
    localStorage.removeItem('token');
  };

  const toggleTheme = () => {
    setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  useEffect(() => {
    //console.log('Current token:', token); // Debugging log
  }, [token]);

  return (
    <ThemeProvider theme={themeMode === 'light' ? lightTheme : darkTheme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage onLoginSuccess={handleLoginSuccess} />} />
          <Route
            path="/"
            element={<LoginPage onLoginSuccess={handleLoginSuccess} />}
          />
          <Route
            path="/seasons"
            element={token ? <Layout onLogout={handleLogout} themeMode={themeMode} toggleTheme={toggleTheme}><SeasonsPage /></Layout> : <Navigate to="/login" />}
          />
          <Route
            path="/seasons/new"
            element={token ? <Layout onLogout={handleLogout} themeMode={themeMode} toggleTheme={toggleTheme}><SeasonFormPage /></Layout> : <Navigate to="/login" />}
          />
          <Route
            path="/seasons/:seasonId/edit"
            element={token ? <Layout onLogout={handleLogout} themeMode={themeMode} toggleTheme={toggleTheme}><EditSeasonPage /></Layout> : <Navigate to="/login" />}
          />
          <Route
            path="/seasons/:seasonId/manage"
            element={token ? <Layout onLogout={handleLogout} themeMode={themeMode} toggleTheme={toggleTheme}><SeasonDashboardPage /></Layout> : <Navigate to="/login" />}
          />
          <Route
            path="/seasons/:seasonId/teams"
            element={token ? <Layout onLogout={handleLogout} themeMode={themeMode} toggleTheme={toggleTheme}><TeamsPage /></Layout> : <Navigate to="/login" />}
          />
          <Route
            path="/seasons/:seasonId/teams/:teamId/edit"
            element={token ? <Layout onLogout={handleLogout} themeMode={themeMode} toggleTheme={toggleTheme}><EditTeamPage /></Layout> : <Navigate to="/login" />}
          />
          <Route
            path="/seasons/:seasonId/generate-fixture"
            element={token ? <Layout onLogout={handleLogout} themeMode={themeMode} toggleTheme={toggleTheme}><GenerateFixturePage /></Layout> : <Navigate to="/login" />}
          />
          <Route
            path="/seasons/:seasonId/inactive-players"
            element={token ? <Layout onLogout={handleLogout} themeMode={themeMode} toggleTheme={toggleTheme}><InactivePlayersPage /></Layout> : <Navigate to="/login" />}
          />
          <Route
            path="/seasons/:seasonId/blog/show"
            element={token ? <Layout onLogout={handleLogout} themeMode={themeMode} toggleTheme={toggleTheme}><ShowPostsPage /></Layout> : <Navigate to="/login" />}
          />
          <Route
            path="/seasons/:seasonId/blog/create"
            element={token ? <Layout onLogout={handleLogout} themeMode={themeMode} toggleTheme={toggleTheme}><BlogPage /></Layout> : <Navigate to="/login" />}
          />
          <Route
            path="/seasons/:seasonId/update-fixtures"
            element={token ? <Layout onLogout={handleLogout} themeMode={themeMode} toggleTheme={toggleTheme}><UpdateFixtures /></Layout> : <Navigate to="/login" />}
          />
          <Route
            path="/seasons/:seasonId/standings"
            element={token ? <Layout onLogout={handleLogout} themeMode={themeMode} toggleTheme={toggleTheme}><Standings /></Layout> : <Navigate to="/login" />}
          />
       
          <Route
            path="/seasons/:seasonId/schedule-maker"
            element={token ? <Layout onLogout={handleLogout} themeMode={themeMode} toggleTheme={toggleTheme}><ScheduleMaker /></Layout> : <Navigate to="/login" />}
          />
          <Route path="*" element={<Navigate to="/seasons" />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
