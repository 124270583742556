import axios from 'axios';
const CLOUD_URL = 'https://services.amatorskasiatka.pl/api'; // Ensure this URL is correct
const LOCAL_URL = 'http://localhost:8000/api'; // Ensure this URL is correct
const API_BASE_URL = CLOUD_URL // Use LOCAL_URL or CLOUD_URL as needed

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const login = (credentials) => api.post('/token/', credentials);

export const fetchCurrentSeasonId = () => api.get('/current-season-id/');

export const fetchSeasons = () => api.get('/seasons/');
export const fetchSeasonById = (seasonId) => api.get(`/seasons/${seasonId}/`);
export const createSeason = (seasonData) => api.post('/create-season/', seasonData);
export const updateSeason = (seasonId, seasonData) => api.put(`/update-season/${seasonId}/`, seasonData);
export const deleteSeason = (seasonId) => api.delete(`/delete-season/${seasonId}/`);

export const fetchTeams = (seasonId) => api.get(`/seasons/${seasonId}/teams/`);
export const fetchTeam = (teamId) => api.get(`/teams/${teamId}/`);
export const createTeam = (teamData) => api.post(`/create-team/`, teamData);  // Ensure this endpoint matches the backend
export const updateTeam = (teamId, teamData) => api.put(`/update-team/${teamId}/`, teamData);
export const deleteTeam = (teamId) => api.delete(`/delete-team/${teamId}/`);

export const fetchPlayers = () => api.get('/players/'); // Add this line
export const createPlayer = (playerData) => api.post('/create-player/', playerData);
export const updatePlayer = (playerId, playerData) => api.put(`/update-player/${playerId}/`, playerData);
export const deletePlayer = (playerId) => api.delete(`/delete-player/${playerId}/`);

export const generateSchedulePreview = (payload) => api.post('/generate-schedule-preview/', payload);
export const finalizeSchedule = (payload) => api.post('/finalize-schedule/', payload);
export const getCurrentSchedule = (seasonId) => api.get(`/seasons/${seasonId}/schedule/`);

export const createPost = (postData) => api.post('/create-post/', postData);
export const listPosts = (postData) => api.get('/posts/');
export const fetchPost = (postId) => api.get('/posts//${postId}/');
export const updatePost = (postId, payload) => api.put(`/update-post/${postId}/`);
export const deletePost = (postId) => api.delete(`/delete-post/${postId}/`);

export const updateMatch = (matchId, matchData) => api.put(`/update-match/${matchId}/`, matchData, {
  headers: {
    'Content-Type': 'application/json'
  }
});

export const fetchMatches = (seasonId) => api.get(`/matches/`);

export const uploadFile = (formData) => api.post(`/upload/`, formData, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});
export const uploadFiles = (formData) => api.post('/upload-many/', formData, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});


export const fetchStandings = (seasonId) => api.get(`/update-standings/${seasonId}/`); // Add this line

export const cleanUpSchedules = () => api.delete('/clean-up-schedules/');


export default api;
