import React, { useEffect, useState } from 'react';
import { fetchMatches, fetchTeams, updateMatch } from '../api'; 
import { useParams } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  Grid,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import AwayIcon from '@mui/icons-material/DirectionsBus';
import EditIcon from '@mui/icons-material/Edit';

const UpdateFixtures = () => {
  const { seasonId } = useParams();
  const [schedule, setSchedule] = useState([]);
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingMatch, setEditingMatch] = useState(null);
  const [homeScore, setHomeScore] = useState(0);
  const [awayScore, setAwayScore] = useState(0);
  const [error, setError] = useState('');
  const [selectedRound, setSelectedRound] = useState(null);

  useEffect(() => {
    const loadSchedule = async () => {
      setLoading(true);
      try {
        const matchesResponse = await fetchMatches(seasonId);
        setSchedule(matchesResponse.data);

        const teamsResponse = await fetchTeams(seasonId);
        setTeams(teamsResponse.data);
      } catch (error) {
        console.error('Error fetching schedule or teams:', error);
      }
      setLoading(false);
    };
    loadSchedule();
  }, [seasonId]);

  const handleEditMatch = (match) => {
    setEditingMatch(match);
    setHomeScore(match.home_score);
    setAwayScore(match.away_score);
  };

  const validateScores = () => {
    const validScores = [
      [0, 0], [3, 0], [3, 1], [3, 2], [0, 3], [1, 3], [2, 3]
    ];
    return validScores.some(([home, away]) => home === homeScore && away === awayScore);
  };

  const handleSaveMatch = async () => {
    if (!editingMatch) {
      alert('Nie wybrano meczu do edycji');
      return;
    }
  
    if (!validateScores()) {
      setError('Nieprawidłowa kombinacja wyników. Dozwolone wyniki to 0:0, 3:0, 3:1, 3:2, 0:3, 1:3, 2:3.');
      return;
    }
  
    const homeTeam = teams.find(team => team.name === editingMatch.home_team);
    const awayTeam = teams.find(team => team.name === editingMatch.away_team);
  
    if (!homeTeam || !awayTeam) {
      alert('Nie znaleziono drużyn');
      return;
    }
  
    try {
      const matchData = {
        home_team_id: homeTeam.id,
        away_team_id: awayTeam.id,
        home_score: homeScore,
        away_score: awayScore,
        match_date: editingMatch.match_date,
        match_time: editingMatch.match_time,
      };
      //console.log('Aktualizowanie meczu z danymi:', matchData);
      await updateMatch(editingMatch.id, matchData);
      setEditingMatch(null);
      alert('Mecz zaktualizowany pomyślnie!');
  
      const response = await fetchMatches(seasonId);
      setSchedule(response.data);
    } catch (error) {
      console.error('Błąd podczas aktualizacji meczu:', error);
      alert('Nie udało się zaktualizować meczu.');
    }
  };

  const groupMatchesByRound = (matches) => {
    const grouped = {};
    matches.forEach((match) => {
      if (!grouped[match.round_number]) {
        grouped[match.round_number] = [];
      }
      grouped[match.round_number].push(match);
    });
    return grouped;
  };

  const groupedSchedule = groupMatchesByRound(schedule);
  const roundNumbers = Object.keys(groupedSchedule);

  const getFullImageUrl = (path) => {
    if (!path) return '';
    return path.startsWith('http') ? path : `${process.env.REACT_APP_API_URL}${path.replace(/^\/media\//, '/media/')}`;
  };

  return (
    <Box className="edit-matches-container p-6 flex flex-col items-center bg-gray-100 dark:bg-gray-900 min-h-screen">
      <Typography variant="h4" gutterBottom className="text-blue-800 dark:text-white">
        Edytuj Mecze
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Box className="mb-4">
            <Grid container spacing={2} justifyContent="center">
              {roundNumbers.map((round) => (
                <Grid item key={round}>
                  <Button
                    variant={selectedRound === round ? 'contained' : 'outlined'}
                    onClick={() => setSelectedRound(round)}
                    className="text-blue-800 dark:text-white"
                  >
                    {round} Kolejka
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>

          {selectedRound ? (
            <Box className="flex flex-wrap justify-center gap-4">
              {groupedSchedule[selectedRound].map((match) => (
                <Box key={match.id} className="p-2 w-full sm:w-1/2 lg:w-1/3">
                  <Card className="mb-4 dark:bg-gray-800 dark:text-white">
                    <CardContent className="flex flex-col items-center gap-4">
                      <Typography className="text-center flex items-center justify-center">
                        <HomeIcon
                          className="text-blue-600 dark:text-blue-300 mr-2"
                          fontSize="small"
                        />
                        <span className="text-gray-700 dark:text-gray-300 mr-4">
                          {match.home_team}
                        </span>
                        <span className="text-2xl font-bold">
                          {match.home_score}
                        </span>
                        <span className="text-lg font-bold mx-4">:</span>
                        <span className="text-2xl font-bold">
                          {match.away_score}
                        </span>
                        <span className="text-gray-700 dark:text-gray-300 ml-4">
                          {match.away_team}
                        </span>
                        <AwayIcon
                          className="text-green-600 dark:text-green-300 ml-2"
                          fontSize="small"
                        />
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={() => handleEditMatch(match)}
                        className="bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded flex items-center"
                      >
                        <EditIcon className="mr-1" fontSize="small" />
                        Edytuj
                      </Button>
                    </CardContent>
                  </Card>
                </Box>
              ))}
            </Box>
          ) : (
            <Typography className="text-gray-500 dark:text-gray-300">
              Wybierz rundę, aby wyświetlić mecze.
            </Typography>
          )}
        </>
      )}

      <Dialog open={!!editingMatch} onClose={() => setEditingMatch(null)}>
        <DialogTitle className="dark:text-white">Edytuj Mecz</DialogTitle>
        <DialogContent className="dark:bg-gray-800 dark:text-white">
          <DialogContentText className="dark:text-gray-300">
            Zaktualizuj wynik dla {editingMatch?.home_team} vs {editingMatch?.away_team}
          </DialogContentText>
          <FormControl fullWidth margin="dense">
            <InputLabel className="dark:text-white">Wynik Gospodarzy</InputLabel>
            <Select
              value={homeScore}
              onChange={(e) => setHomeScore(e.target.value)}
              label="Wynik Gospodarzy"
              className="dark:bg-gray-700 dark:text-white"
            >
              {[0, 1, 2, 3].map((score) => (
                <MenuItem key={score} value={score}>
                  {score}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel className="dark:text-white">Wynik Gości</InputLabel>
            <Select
              value={awayScore}
              onChange={(e) => setAwayScore(e.target.value)}
              label="Wynik Gości"
              className="dark:bg-gray-700 dark:text-white"
            >
              {[0, 1, 2, 3].map((score) => (
                <MenuItem key={score} value={score}>
                  {score}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions className="dark:bg-gray-800">
          <Button onClick={() => setEditingMatch(null)} className="dark:text-white">
            Anuluj
          </Button>
          <Button onClick={handleSaveMatch} className="dark:text-white">
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
        <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UpdateFixtures;
