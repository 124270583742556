import React from 'react';
import { Link } from 'react-router-dom';

const SeasonDetails = ({ season }) => {
  return (
    <div className="mb-6">
      <h1 className="text-3xl font-bold mb-4">
        <Link to={`/seasons/${season.id}/edit/`}>
          {season.name} ({season.year})
        </Link>
      </h1>
      <p className="text-lg">Data rozpoczęcia: {season.start_date}</p>
      <p className="text-lg">Data zakończenia: {season.end_date}</p>
      <p className="text-lg">Aktualna runda: {season.current_round}</p>
    </div>
  );
};

export default SeasonDetails;
