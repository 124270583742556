import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { getCurrentSchedule, finalizeSchedule, generateSchedulePreview, cleanUpSchedules } from '../api';
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Typography,
  CircularProgress,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Switch,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import AwayIcon from '@mui/icons-material/DirectionsBus';
import SaveIcon from '@mui/icons-material/Save';
import GenerateIcon from '@mui/icons-material/Autorenew';

const ItemTypes = {
  MATCH: 'match',
};

const DraggableMatch = ({ match, index, legIndex, roundIndex, moveMatch, selectMatch, selected }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.MATCH,
    item: { index, legIndex, roundIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.MATCH,
    hover: (item) => {
      if (item.index !== index || item.legIndex !== legIndex || item.roundIndex !== roundIndex) {
        moveMatch(item.legIndex, item.roundIndex, item.index, legIndex, roundIndex, index);
        item.index = index;
        item.legIndex = legIndex;
        item.roundIndex = roundIndex;
      }
    },
  });

  return (
    <div ref={(node) => drag(drop(node))} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <Card className={`mb-2 shadow-lg border ${selected ? 'border-red-500' : 'border-gray-300'} dark:border-gray-700 dark:bg-gray-800`}>
        <CardContent className="flex items-center justify-between">
          <Typography variant="body2" className="text-gray-700 dark:text-gray-200">
            <HomeIcon className="text-blue-600 dark:text-blue-400 mr-1" fontSize="small" /> {match.home}
          </Typography>
          <Typography variant="body2" className="text-gray-700 dark:text-gray-200">
            <AwayIcon className="text-green-600 dark:text-green-400 mr-1" fontSize="small" /> {match.away}
          </Typography>
          <Switch checked={selected} onChange={() => selectMatch({ legIndex, roundIndex, index })} />
        </CardContent>
      </Card>
    </div>
  );
};

const DropZone = ({ roundIndex, legIndex, moveMatch }) => {
  const [, drop] = useDrop({
    accept: ItemTypes.MATCH,
    drop: (item) => {
      moveMatch(item.legIndex, item.roundIndex, item.index, legIndex, roundIndex, 0);
    },
  });

  return (
    <div ref={drop} className="h-16 border-4 border-dashed border-gray-400 dark:border-gray-600 flex items-center justify-center">
      <Typography variant="body2" className="text-gray-500 dark:text-gray-300">
        Drop here
      </Typography>
    </div>
  );
};

const GenerateFixturePage = () => {
  const { seasonId } = useParams();
  const [schedule, setSchedule] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmGenerateOpen, setConfirmGenerateOpen] = useState(false);
  const [confirmFinalizeOpen, setConfirmFinalizeOpen] = useState(false);
  const [selectedMatches, setSelectedMatches] = useState([]);

  useEffect(() => {
    const loadSchedule = async () => {
      setLoading(true);
      try {
        const response = await getCurrentSchedule(seasonId);
        //console.log(response.data); // Print fetched data to the console
        setSchedule(response.data);
      } catch (error) {
        console.error('Error fetching schedule:', error);
      }
      setLoading(false);
    };
    loadSchedule();
  }, [seasonId]);

  const moveMatch = (fromLegIndex, fromRoundIndex, fromIndex, toLegIndex, toRoundIndex, toIndex) => {
    const newSchedule = JSON.parse(JSON.stringify(schedule));
    const [removed] = newSchedule.legs[fromLegIndex].rounds[fromRoundIndex].matches.splice(fromIndex, 1);
    newSchedule.legs[toLegIndex].rounds[toRoundIndex].matches.splice(toIndex, 0, removed);
    setSchedule(newSchedule);
  };

  const selectMatch = (match) => {
    const newSelectedMatches = [...selectedMatches];
    const existingIndex = newSelectedMatches.findIndex(
      (m) => m.legIndex === match.legIndex && m.roundIndex === match.roundIndex && m.index === match.index
    );

    if (existingIndex > -1) {
      newSelectedMatches.splice(existingIndex, 1);
    } else {
      if (newSelectedMatches.length === 2) {
        newSelectedMatches.pop();
      }
      newSelectedMatches.push(match);
    }

    setSelectedMatches(newSelectedMatches);

    if (newSelectedMatches.length === 2) {
      const [firstMatch, secondMatch] = newSelectedMatches;
      swapMatches(firstMatch, secondMatch);
      setSelectedMatches([]);
    }
  };

  const swapMatches = (firstMatch, secondMatch) => {
    const newSchedule = JSON.parse(JSON.stringify(schedule));
    const temp = newSchedule.legs[firstMatch.legIndex].rounds[firstMatch.roundIndex].matches[firstMatch.index];
    newSchedule.legs[firstMatch.legIndex].rounds[firstMatch.roundIndex].matches[firstMatch.index] =
      newSchedule.legs[secondMatch.legIndex].rounds[secondMatch.roundIndex].matches[secondMatch.index];
    newSchedule.legs[secondMatch.legIndex].rounds[secondMatch.roundIndex].matches[secondMatch.index] = temp;
    setSchedule(newSchedule);
  };

  const openGenerateDialog = () => setConfirmGenerateOpen(true);
  const closeGenerateDialog = () => setConfirmGenerateOpen(false);

  const openFinalizeDialog = () => setConfirmFinalizeOpen(true);
  const closeFinalizeDialog = () => setConfirmFinalizeOpen(false);

  const handleGenerate = async () => {
    try {
      const response = await generateSchedulePreview({ year: '2024/2025', num_legs: 2 });
      setSchedule(response.data);
      closeGenerateDialog();
    } catch (error) {
      console.error('Error generating schedule:', error);
      alert('Nie udało się wygenerować terminarza.');
    }
  };

  const handleFinalize = async () => {
    await saveSchedule();
    closeFinalizeDialog();
  };

  const saveSchedule = async () => {
    try {
      // Finalize the new schedule
      await finalizeSchedule(schedule);
      // Clean up previous non-finalized schedules
      await cleanUpSchedules();
      alert('Terminarz zapisany pomyślnie!');
    } catch (error) {
      console.error('Error saving schedule:', error);
      alert('Nie udało się zapisać terminarza.');
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Box className="p-6 flex flex-col items-center bg-gray-100 dark:bg-gray-800 min-h-screen">
        <Typography variant="h4" className="text-4xl font-bold mb-6 text-blue-800 dark:text-blue-400">
          Kalendarz Rozgrywek
        </Typography>
        {schedule && (
          <Card className="mb-6 w-full max-w-6xl shadow-lg dark:bg-gray-900 dark:text-white">
            <CardContent>
              <Typography variant="h6" className="text-2xl font-semibold mb-4 dark:text-gray-100">
                Obecny Terminarz
              </Typography>
              <div className="flex justify-center gap-4">
              <Button
          variant="contained"
          color="primary"
          onClick={openGenerateDialog}
          startIcon={<GenerateIcon />}
          className="bg-blue-600 hover:bg-blue-700 text-white font-semibold p-6 m-4 rounded"
        >
          Generuj Nowy
        </Button>              
        <Button
                variant="contained"
                color="secondary"
                onClick={openFinalizeDialog}
                startIcon={<SaveIcon />}
                className="bg-green-600 hover:bg-green-700 text-white font-semibold p-2 m-4 rounded"
              >
                Zapisz 
              </Button>        
              </div>
              <Box className="flex flex-wrap gap-4 py-6">
                {schedule.legs.map((leg, legIndex) => (
                  <Card key={legIndex} className="flex-1 min-w-80 p-4 bg-white shadow-md dark:bg-gray-800 dark:text-gray-100">
                    <CardContent>
                      <Typography variant="subtitle1" className="text-xl font-bold text-blue-600 dark:text-blue-300 mb-2">
                        Runda {legIndex + 1}
                      </Typography>
                      {leg.rounds.map((round, roundIndex) => (
                        <div key={roundIndex} className="mb-4 p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
                          <Typography variant="subtitle2" className="text-lg font-semibold text-green-700 dark:text-green-400 mb-2 text-center">
                            Kolejka {roundIndex + 1}
                          </Typography>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <DropZone legIndex={legIndex} roundIndex={roundIndex} moveMatch={moveMatch} />
                            </Grid>
                            {round.matches.map((match, index) => (
                              <Grid item xs={12} key={index}>
                                <DraggableMatch
                                  match={match}
                                  index={index}
                                  legIndex={legIndex}
                                  roundIndex={roundIndex}
                                  moveMatch={moveMatch}
                                  selectMatch={selectMatch}
                                  selected={selectedMatches.some(
                                    (m) =>
                                      m.legIndex === legIndex &&
                                      m.roundIndex === roundIndex &&
                                      m.index === index
                                  )}
                                />
                              </Grid>
                            ))}
                          </Grid>
                        </div>
                      ))}
                    </CardContent>
                  </Card>
                ))}
              </Box>
            </CardContent>

            <div className="flex justify-center gap-4 my-6">
                          <Button
                      variant="contained"
                      color="primary"
                      onClick={openGenerateDialog}
                      startIcon={<GenerateIcon />}
                      className="bg-blue-600 hover:bg-blue-700 text-white font-semibold p-6 m-4 rounded"
                    >
                      Generuj Nowy
                    </Button>              
                    <Button
                            variant="contained"
                            color="secondary"
                            onClick={openFinalizeDialog}
                            startIcon={<SaveIcon />}
                            className="bg-green-600 hover:bg-green-700 text-white font-semibold p-2 m-4 rounded"
                          >
                            Zapisz
                          </Button>        
                          </div>  

          </Card>
        )}

  
        <Dialog open={confirmGenerateOpen} onClose={closeGenerateDialog}>
          <DialogTitle className="dark:text-gray-100">Potwierdź Generowanie Harmonogramu</DialogTitle>
          <DialogContent className="dark:bg-gray-800 dark:text-gray-100">
            <DialogContentText className="dark:text-gray-300">
              Czy na pewno chcesz wygenerować nowy harmonogram? Spowoduje to nadpisanie istniejącego harmonogramu.
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dark:bg-gray-800">
            <Button onClick={closeGenerateDialog} color="primary">
              Anuluj
            </Button>
            <Button onClick={handleGenerate} color="primary">
              Potwierdź
            </Button>
          </DialogActions>
        </Dialog>
  
        <Dialog open={confirmFinalizeOpen} onClose={closeFinalizeDialog}>
          <DialogTitle className="dark:text-gray-100">Potwierdź Finalizację Harmonogramu</DialogTitle>
          <DialogContent className="dark:bg-gray-800 dark:text-gray-100">
            <DialogContentText className="dark:text-gray-300">
              Czy na pewno chcesz sfinalizować obecny harmonogram? Ta akcja nie może zostać cofnięta.
            </DialogContentText>
          </DialogContent>
          <DialogActions className="dark:bg-gray-800">
            <Button onClick={closeFinalizeDialog} color="primary">
              Anuluj
            </Button>
            <Button onClick={handleFinalize} color="primary">
              Potwierdź
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </DndProvider>
  );
};

export default GenerateFixturePage;
